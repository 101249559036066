<template>
  <div class="Title">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: 'TitleFont',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.Title {
 font-family: Avenir, Helvetica, Arial, sans-serif;
 color: azure;
 text-align: center;
 vertical-align: middle;
 padding-top: 20%;
}
h1, title {
  margin-bottom: 0px;
}
</style>
