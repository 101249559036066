<template>
  <NavigationBar>
    
  </NavigationBar>
  <TitleFont msg="Dan Brooks"/>
  <TextList msg="..."/>
 <TextList msg="Network Engineer"/>
</template>

<script>
import TitleFont from './components/Title.vue'
import TextList from './components/Text.vue'
import NavigationBar from './components/Navbar.vue'
export default {
  name: 'App',
  components: {
    TitleFont,
    TextList,
    NavigationBar
  }
}
</script>
<style>
body, main {
background-color: #008AD7;
margin: 0%;
}
</style>
